import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    padding: '0 10px 0 10px',
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center'
  }
})

type Props = {};

export const Overview: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant={'body1'} className={classes.text}>
        I am an accomplished Team Lead and Manager with over 11 years of practical experience in the realm of contemporary endeavors. My professional journey has encompassed a wide spectrum of responsibilities, including contract negotiations, project planning, project execution, and leadership, all geared towards effectively managing extensive volumes of critical business data.

My technical expertise covers a diverse range of fields, including backend and frontend development, database management, and proficiency in DevOps practices. I have consistently integrated the latest technological advancements to enhance our production infrastructure, while also fostering knowledge-sharing among colleagues and staff members.

In the domain of management, I have refined my skills in orchestrating business deliveries, overseeing organizational operations, implementing effective team-building strategies, and efficiently managing the company's personnel. I am genuinely enthusiastic about aligning cutting-edge informatics solutions with tangible business outcomes, particularly as our company continues to expand and evolve.
      </Typography>
    </div>
  );
}