import { config } from '../config';

export type Publication = {
  title: string;
  description: string;
  date: Date;
  logo: string;
  link: string;
};

export const PUBLICATION_LIST: Publication[] = [
  {
    title: 'Versioning Automation Management Tool',
    description: 'You have always been interested what does the version of the product mean and how to manage these versions. Let’s consider these questions.',
    date: new Date(2018, 11, 29),
    logo: `${config.s3.route}/reddit-logo.png`,
    link: 'https://www.reddit.com/r/opensource/comments/aaewcn/versioning_automation_management_tool/', // @todo: publication link
  },
  {
    title: 'Инструмент автоматизации управления версиями',
    description: 'Всегда было интересно, что такое версии продукта и как ими управлять? Как автоматизировать управление версиями разработки? Постараемся ответить на эти вопросы.',
    date: new Date(2018, 11, 21),
    logo: `${config.s3.route}/habr-logo.png`,
    link: 'https://habr.com/en/articles/433994/', // @todo: publication link
  },
];