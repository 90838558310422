import React from 'react';
import { v1 as uuid } from 'uuid';
import { Uid } from './common.model';
import { Download, Email, Link, LinkedCamera, LinkedIn, Message } from '@mui/icons-material';
import { copyUrl, openUrl } from '../utils/window';
import { config } from '../config';
import { Maybe } from '../types';

export type Contact = {
  uid: Uid;
  text: string;
  action: () => void;
  icon: React.ReactElement;
  tooltip: ContactTooltip;
};

type ContactTooltip = {
  active?: Maybe<string>;
  inactive: string;
}

export const CONTACT_LIST: Contact[] = [
  {
    uid: uuid(),
    text: 'Raman Marozau',
    action: () => {
      openUrl(config.content.link.linkedIn);
    },
    icon: <LinkedIn />,
    tooltip: {
      inactive: 'LinkedIn'
    },
  },
  {
    uid: uuid(),
    text: config.content.link.email,
    action: () => {
      openUrl(`mailto:${config.content.link.email}`);
    },
    icon: <Email />,
    tooltip: {
      inactive: 'Send email',
    },
  },
  {
    uid: uuid(),
    text: 'Raman Marozau',
    action: () => {
      openUrl(`skype:${config.content.link.skype}`);
    },
    icon:
      <>
        <img
          src={'/skype-icon.png'}
          alt={'Skype Raman Marozau'}
          height={'25px'}
        />
      </>,
    tooltip: {
      inactive: 'Skype',
    },
  },
  {
    uid: uuid(),
    text: 'Solution Architect. Raman Marozau',
    action: () => {
      openUrl(`${config.s3.route}/${config.s3.filename}`);
    },
    icon: <Download />,
    tooltip: {
      inactive: 'Download CV, PDF',
      active: 'Opened in new tab...'
    },
  },
  {
    uid: uuid(),
    text: 'Solution Architect. Raman Marozau',
    action: () => {
      copyUrl(`${config.protocol}://${config.domain}`);
    },
    icon: <Link />,
    tooltip: {
      inactive: 'Copy CV url',
      active: 'Copied'
    },
  },
]